import {useContext} from "react";
import GeneralContext from "../store/general";
import Link from "next/link";
import {countryMySamSamLink} from "../lib/countryConfig";

export default function MobileMenu({menus}) {
    const generalContext = useContext(GeneralContext);

    function toggleMobileMenu() {
        generalContext.toggleMobileMenu();
    }

    return (
        <div
            className={`mobile-menu ${
                generalContext.mobileMenuOpen ? "mobile-menu--is-open" : ""
            }`}
        >
            <button onClick={toggleMobileMenu} className="mobile-menu__close">
                <i className="fas fa-times"/>
            </button>
            <div className="mobile-menu__background-logo"/>
            <div className="mobile-menu__content">
                {/*<Link href="/">*/}
                {/*    <a className="mobile-menu__logo" onClick={toggleMobileMenu}/>*/}
                {/*</Link>*/}
                <ul>
                    {menus["mobile-menu"].items.map((item) => {
                        if (item.url === "#" && item.subItems) {
                            return (
                                <li key={item.url}>
                                    <div
                                        className="tw-flex tw-flex-wrap tw-w-full tw-gap-y-10 tw-py-12 tw-px-4">
                                        {item.subItems.map((subItem) => {
                                            return (
                                                <div className="tw-w-1/3">
                                                    <Link href={subItem.url} target={subItem.target}>
                                                        <a className="tw-px-16 tw-group !tw-flex tw-flex-col tw-justify-center tw-text-center tw-items-center tw-gap-y-3 !tw-text-[18px] !tw-p-0 hover:!tw-text-black"
                                                           onClick={toggleMobileMenu}>
                                                            <span
                                                                className="tw-bg-gray-100 tw-w-[70px] tw-h-[70px] tw-rounded-2xl tw-grow-0 group-hover:tw-bg-gray-200 tw-transition tw-p-4">
                                                                <img
                                                                    src={subItem.iconSrc}
                                                                    alt={subItem.title}
                                                                    className="tw-object-contain tw-w-full tw-h-full"/>
                                                            </span>
                                                            <span>{subItem.title}</span>
                                                        </a>
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </li>
                            );
                        } else {
                            return (
                                <li key={item.url}>
                                    <Link href={item.url} target={item.target}>
                                        <a onClick={toggleMobileMenu}>{item.title}</a>
                                    </Link>
                                </li>
                            );
                        }
                    })}
                </ul>
                {countryMySamSamLink.url && <div className="mt-20">
                    <Link href={countryMySamSamLink.url}>
                        <a className="mobile-menu__account-link" onClick={toggleMobileMenu}>
                            <i className="fas fa-user-circle"/>
                            <span>{countryMySamSamLink.title}</span>
                        </a>
                    </Link>
                </div>}
            </div>
        </div>
    );
}
